import React, { useEffect, useState } from 'react';
import { FormControl, Image } from 'react-bootstrap';
import trimCanvas from 'trim-canvas';
import CircularProgressBar from '../common/circular-progress';

const fonts = ['Sacramento', 'Allura', 'Alex Brush', 'Parisienne', 'Herr Von Muellerhoff', 'Yellowtail'];

const generateSignatureFromText = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = `30px '${font}'`; // Set font for measurement

  // Measure text width and add padding
  const textWidth = context.measureText(text).width;
  canvas.width = textWidth + 20; // Add padding to the canvas width
  canvas.height = 50; // Canvas height, adjust as needed

  // Set font and style again after resizing
  context.font = `30px '${font}'`;
  context.fillStyle = '#000'; // Text color

  // Adjust the y-coordinate to be the baseline of the text
  const yPosition = canvas.height / 2 + 10; // Center vertically
  context.fillText(text, 10, yPosition); // Add horizontal padding

  // Trim the canvas to remove blank space
  const trimmedCanvas = trimCanvas(canvas);

  // Convert the trimmed canvas to an image
  return trimmedCanvas.toDataURL('image/png');
};

const SignatureTemplate = ({ text, setText, signatures, setSignatures, onSelectSignature, selectedSignature }) => {
  const [loadingSignatureFonts, setLoadingSignatureFonts] = useState(true);

  const handleGenerate = () => {
    const generatedSignatures = fonts.map(font => generateSignatureFromText(text, font));
    setSignatures(generatedSignatures);
  };

  const loadFontsAndGenerate = async () => {
    setLoadingSignatureFonts(true);
    try {
      // Wait for all specified fonts to be loaded
      await Promise.all(fonts.map(font => document.fonts.load(`30px '${font}'`)));
      console.log('All custom fonts are loaded');
      handleGenerate();
    } catch (error) {
      console.error('Error loading fonts:', error);
      handleGenerate(); // Fallback to generate even if fonts fail to load
    }
    setLoadingSignatureFonts(false);
  };

  useEffect(() => {
    if (text) {
      loadFontsAndGenerate();
    } else {
      setSignatures([]);
    }
  }, [text]);

  return (
    <div
      style={{
        minHeight: 301
      }}
    >
      <div className="d-flex gap-2 align-items-center">
        <h6 className="smallFont mb-0">Signature Text:</h6>
        <FormControl
          className="w-50"
          size="sm"
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Enter your name"
        />
      </div>
      <hr className="my-2" />
      <h6 className="smallFont mt-3 mb-2">Click on one of the signature below to select:</h6>
      {loadingSignatureFonts && (
        <div className="text-center my-4">
          <CircularProgressBar size={3} />
        </div>
      )}
      <div className="mt-0 d-flex flex-wrap gap-2">
        {signatures.map((src, index) => (
          <div
            key={index}
            onClick={() => onSelectSignature(src)}
            className="hover p-1"
            style={{
              border: selectedSignature === src ? `2px solid #5F0123` : '1px solid gray'
            }}
          >
            <Image src={src} alt={`Signature sss ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignatureTemplate;
