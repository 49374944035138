import moment from 'moment';
import React, { useMemo } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { ArrowsCollapse, ArrowsExpand, Trash } from 'react-bootstrap-icons';
import UnderlineButton from '../common/UnderlineButton';

const CommentRow = ({ comment, role, onCommentDeleteClick }) => {
  return (
    <div key={comment['_id']}>
      <p className=" text-right mb-1">
        <i className="text-muted d-block tinyFont">
          On {moment(comment['timestamp']).format('MMMM Do YYYY, h:mm:ss a')}
        </i>
      </p>
      <div className="border bg-light rounded px-3 py-2 mt-1 midFont">
        <div className="d-flex">
          <div className="flex-grow-1">
            <b className="mb-0 text-info">{comment['userName']}</b>
          </div>
          {role === 'super admin' && onCommentDeleteClick && (
            <div className="text-danger hover-underline tinyFont" onClick={() => onCommentDeleteClick(comment)}>
              <Trash size={10} /> Delete
            </div>
          )}
        </div>
        {comment['comment']}
      </div>
    </div>
  );
};

const CommentsCompact = ({
  comments,
  disabled,
  onCommentSubmit,
  commentText,
  onCommentTextChange,
  onCommentDeleteClick,
  expandedMode,
  onCommentExpandClick
}) => {
  const role = useMemo(() => localStorage.getItem('user-role'), []);
  const commentsSorted = useMemo(
    () => comments?.sort((c1, c2) => new Date(c1.timestamp) - new Date(c2.timestamp)) || [],
    [comments]
  );

  return (
    <>
      {commentsSorted?.length > 0 ? (
        expandedMode ? (
          commentsSorted.map(comment => {
            return (
              <CommentRow
                key={comment['_id']}
                comment={comment}
                role={role}
                onCommentDeleteClick={onCommentDeleteClick}
              />
            );
          })
        ) : (
          <CommentRow
            comment={commentsSorted[commentsSorted?.length - 1]}
            role={role}
            onCommentDeleteClick={onCommentDeleteClick}
          />
        )
      ) : (
        <p className="text-secondary midFont my-1">No comments to show!</p>
      )}

      {expandedMode && (
        <>
          <hr className="my-2" />
          <FormControl
            id="comments"
            as="textarea"
            className="mt-4"
            size="sm"
            value={commentText}
            onChange={e => onCommentTextChange(e.target.value)}
            placeholder="Type your comment here..."
          ></FormControl>
          <div className="text-right">
            <Button
              disabled={disabled || commentText.length === 0}
              className="mt-2"
              size={'sm'}
              onClick={onCommentSubmit}
            >
              Add Comment
            </Button>
          </div>
        </>
      )}

      <div className="text-right mt-2">
        <UnderlineButton
          fontSize="large"
          iconSize={12}
          text={expandedMode ? 'Collapse' : 'View More'}
          Icon={expandedMode ? ArrowsCollapse : ArrowsExpand}
          onClick={() => onCommentExpandClick(!expandedMode)}
          variant="primary"
        />
      </div>
    </>
  );
};

export default CommentsCompact;
